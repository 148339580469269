import React from 'react'

const Image = ({url}) => {
    return (
        <>
           <img src={url} alt="image"/> 
        </>
    )
}

export default Image
