import React from 'react'
import '../../assets/scss/elements/_spinner.scss'

const Spinner = () => {
    return (
        <div className="loading"></div>
    )
}

export default Spinner
         