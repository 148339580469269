import React from 'react'
import InviteForm from '../../components/Form/RequestInviteForm'

const RequestInvitation = () => {
    return (
        <>
            <InviteForm/>
        </>
    )
}

export default RequestInvitation