import React from 'react'
import '../../assets/scss/elements/_loader.scss'


const Loader = () => {
    return (
        <div id="cover-spin"></div>
    )
}

export default Loader
